import React, { Component } from 'react';
import Rounds from './Rounds.js';
import Conditions from './Conditions.js';

class Roor extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			realitySkill: 10,
			zoneModifier: MIXED,
			skilledTest: true,

			destinyBoosts: 0,
			favored: false,
			unfavored: false,
			
			realmrunner: false,
			realityfocus: false,
			
			result: DISCONNECT,
			
			diceRolls: [],
			rounds: [],
		};
	}
	
	render() {
		return (
			<div className="Roor container" >
			<img alt="Torg Eternity" style={{marginTop: '50px', width: '50%', minWidth: '350px'}} src='TORG_Logo.png'/>
			<h3 style={{margin: '20px'}}>Reconnect out of Rounds</h3>

				<div className="formrow row justify-content-center">
					<div className="col-md-2">
						<label htmlFor="realitySkill">Reality</label>
						
						<select id="realitySkill" className="custom-select" value={this.state.realitySkill} style={{textAlignLast: 'center'}} onChange={
							(e) => {
								this.setState({realitySkill: parseInt(e.target.value)})
								this.clearRounds();
							}
						}>
							{ [...Array(50).keys()].map( ( nbr ) => (
								<option value={nbr + 1} key={nbr}>{nbr + 1}</option>
							) ) }
						</select>
					</div>
							
					<div className="col-md-2">
						<label htmlFor="zoneType">Zone</label>
						
						<select id="zoneType" className="custom-select" value={this.state.zoneInput} style={{textAlignLast: 'center'}} onChange={
							(e) => {
								this.setState({zoneModifier: parseInt(e.target.value)})
								this.clearRounds();
							}
						}>
								<option value={MIXED}>Mixed</option>
								<option value={DOMINANT}>Dominant</option>
								<option value={PURE}>Pure</option>
						</select>
					</div>
				</div>
						
				<Conditions
					realmRunner={this.state.realmrunner}
					onRealmRunnerChange={this.handleRealmRunnerChange}
					
					realityFocus={this.state.realityfocus}
					onRealityFocusChange={this.handleRealityFocusChange}
					
					destinyBoosts={this.state.destinyBoosts}
					onDestinyBoostsChange={this.handleDestinyBoostsChange}
					
					clearRounds={this.clearRounds}
				/>
											
				<div className="roorButton row align-items-center" style={{margin: '30px'}}>
					<div className="col">
						<input type="button" className="btn btn-lg btn-primary" value="Reconnect" onClick={this.Reconnect} />
					</div>
				</div>

				<div className="results" id="results">
					{this.state.rounds.reverse().map( (round, roundIndex) => (
						<Rounds key={roundIndex} round={round} roundNumber={this.state.rounds.length - roundIndex}/>
					))}
				</div>
			</div>
		);
	}
	
	resetState = () => {
		this.setState({
			diceRolls : [],
		});
	}
	
	handleRealmRunnerChange = (realmrunner) => {
		this.setState({realmrunner: realmrunner});
		this.clearRounds();
	}
	
	handleRealityFocusChange = (realityfocus) => {
		this.setState({realityfocus: realityfocus});
		this.clearRounds();
	}
	
	handleDestinyBoostsChange = (destinyBoosts) => {
		this.setState({destinyBoosts: parseInt(destinyBoosts)});
		this.clearRounds();
	}
	
	clearRounds = () => {
		this.setState({rounds: []});
	}
	
	Reconnect = () => {
		this.resetState();
		this.state.rounds = [];
		this.state.result = DISCONNECT;

		while ( this.state.result === DISCONNECT ) {
			let thisroll = this.roll();

			let bonus = this.getBonus( thisroll );
			
			let testTotal =
				this.state.realitySkill
				+ bonus
				+ ( this.state.realityfocus ? 4 : 0 )
				+ ( this.state.realmrunner ? Math.min(0, this.state.zoneModifier + 4) : this.state.zoneModifier );
			
			if ( thisroll === 1 ) {
				this.state.result = TRANSFORM;
			} else if ( testTotal + this.state.destinyBoosts >= 10 ) {
				this.state.result = RECONNECT;
			}
			
			this.state.rounds.push({
				zoneModifier: this.state.zoneModifier,
				realmrunner: this.state.realmrunner,
				rollTotal: thisroll,
				bonus: bonus,
				testTotal: testTotal,
				destinyBoosts: this.state.destinyBoosts,
				rolls: [].concat(this.state.diceRolls),
				result: this.state.result
			});
			
			this.state.diceRolls = [];
		}
	}

	roll = () => {
		var roll = Math.floor(Math.random() * 20) + 1;

		this.state.diceRolls.push(roll);

		if( ( roll === 20 && this.state.skilledTest ) || roll === 10 ) {
			roll += this.roll();
		}

		return roll;
	}
	
	getBonus = ( roll ) => {
		var bonuschart = {
			1 : -10,
			2 : -8,
			3 : -6,
			4 : -6,
			5 : -4,
			6 : -4,
			7 : -2,
			8 : -2,
			9 : -1,
			10 : -1,
			11 : 0,
			12 : 0,
			13 : 1,
			14 : 1,
			15 : 2,
			16 : 3,
			17 : 4,
			18 : 5,
			19 : 6,
			20 : 7
		};

		if( roll <= 20 ) {
			return bonuschart[roll];
		} else {
			return Math.floor( ( roll - 1 ) / 5 ) + 4 
		}
	}
	
	displayMsg = ( msg ) => {
		let message = document.createElement('div');
		message.classList.add('message');
		message.innerHTML = msg;
		
		let list = document.getElementById('results');
		list.insertBefore(message, list.childNodes[0]);
	}
}


const MIXED = 0;
const DOMINANT = -4;
const PURE = -8;

const DISCONNECT = 'Failed';
const TRANSFORM = 'Transformed';
const RECONNECT = 'Reconnected';
/*const GLORY = '60+ Reconnected';*/

export default Roor;

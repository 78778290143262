import React, { Component } from 'react';
import './App.css';
import Roor from './components/Roor';

class App extends Component {
	// state = {
	// 	roll
	// };
	//
	render() {
		return (
			<div className="App">
				<Roor />
			</div>
		);
	}
}

export default App;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Rounds extends Component {
	render() {
		let minutes = Math.floor(this.props.roundNumber / 6);
		let seconds = (this.props.roundNumber % 6) * 10;

		return (
			<div className={this.props.round.result + ' Round'}>

				<div className='row'>
					<div className='col elapsedTime'>Elapsed Time:<br/>{ minutes }:{ ("0" + seconds.toString()).substring(seconds.toString().length - 1) }</div>
					<div className='col'><h4>{ this.props.round.result }</h4></div>
					<div className='col dnResults'>
						{ this.props.round.realmrunner &&
							<div className='dnResults'>Realm Runner</div>
						}
						<div className='dnResults'>Zone Modifier: { this.props.round.realmrunner ? Math.min(0,this.props.round.zoneModifier+4) : this.props.round.zoneModifier }</div>
					</div>
				</div>
			
				<div className='row'>
					<div className='col'>Test Total: { this.props.round.testTotal }</div>
				</div>

				<div className='row'>
					<div className='col'>Roll Total: { this.props.round.rollTotal }</div>
					<div className='col'>Rolls: { this.props.round.rolls.map((roll) => { 
						return("[" + roll + "] " )
					 }) }</div>
				</div>
			
				<div className='row'>
					<div className='col'>Bonus: { this.props.round.bonus }</div>
					<div className='col'>
					 {
						 ( this.props.round.result === "Reconnected" && this.props.round.testTotal < 10 )
							 ? 'Destiny Cards Used: ' + Math.ceil(( 10 - this.props.round.testTotal ) / 3).toString()
						 	: ''
					 }
						
					</div>
				</div>

			</div>
		);
	}
}

// PropTypes
Rounds.propTypes = {
	round : PropTypes.object.isRequired,
	roundNumber : PropTypes.number.isRequired
}
	
export default Rounds;

import React, { Component } from 'react';

class RoorConditions extends Component {
	constructor(props) {
		super(props);
		this.state = {conditionToggle: false};
	}
	
	render() {
		return (
			<div>
				<ConditionToggleRow
					componentChecked={this.state.conditionToggle}
					toggleCondition={this.toggleCondition}
				/>
					
				<ConditionsRow
					conditionToggle={this.state.conditionToggle}

					realmRunner={this.props.realmRunner}
					onRealmRunnerChange={this.props.onRealmRunnerChange}

					realityFocus={this.props.realityFocus}
					onRealityFocusChange={this.props.onRealityFocusChange}
					
					destinyBoosts={this.props.destinyBoosts}
					onDestinyBoostsChange={this.props.onDestinyBoostsChange}
				/>
			</div>
		);
	}
	
	toggleCondition = (checked) => {
		this.props.onRealmRunnerChange(false);
		this.props.onRealityFocusChange(false);
		this.props.onDestinyBoostsChange(0);
		this.setState({conditionToggle: checked});
		
		this.props.clearRounds();
	}
}

class ConditionToggleRow extends Component {
	render() {
		return (
			<div className="row">
				<div className="col">
					<div className="custom-control custom-control-right custom-switch">
						<input type="checkbox"
							className="custom-control-input"
							id="ConditionsSwitch"
							checked={this.props.componentChecked}
							onChange={(e) => {
								this.props.toggleCondition(e.target.checked);
							}}
						/>
						<label className="custom-control-label" htmlFor="ConditionsSwitch">Use Conditions</label>
					</div>
				</div>						
			</div>
		);
	}
}

class ConditionsRow extends Component {
	render() {
		return (
			<div className="formrow row justify-content-middle" id="conditions" style={{display: this.props.conditionToggle ? 'block' : 'none'}}>
				<div className="col">
						
					<DestinyCards
						destinyBoosts={this.props.destinyBoosts}
						onDestinyBoostsChange={this.props.onDestinyBoostsChange}
					/>
<hr/>

					<RealityMechanics
						realmRunner={this.props.realmRunner}
						onRealmRunnerChange={this.props.onRealmRunnerChange}

						realityFocus={this.props.realityFocus}
						onRealityFocusChange={this.props.onRealityFocusChange}
					/>
				</div>
			</div>
		);
	}
}

class RealityMechanics extends Component {
	render() {
		return(
			<div className="row condrow justify-content-center">
		
				<div className="col-*-5 text-center condHeaderCell" style={{marginTop: 'auto', marginBottom: 'auto'}}>
					<span>Reality Mechanics</span>
				</div>

				<div className="col-*-5 offset-2 text-left condCell" style={{marginTop: 'auto', marginBottom: 'auto'}}>
					<div className="form-check form-check">
					  <input className="form-check-input" type="checkbox" name="inlineRadioOptions"
							id="realmrunner"
							checked={this.props.realmRunner}
							onChange={(e) => {
								this.props.onRealmRunnerChange(e.target.checked);
							} }
						/>
					  <label className="form-check-label" htmlFor="realmrunner">Realm Runner</label>
					</div>

					<div className="form-check form-check">
					  <input className="form-check-input" type="checkbox" name="inlineRadioOptions"
							id="realityfocus"
							checked={this.props.realityFocus}
							onChange={(e) => {
								this.props.onRealityFocusChange(e.target.checked);
							} }
						/>
					  <label className="form-check-label" htmlFor="realityfocus">Reality Focus</label>
					</div>
				</div>
			</div>
		)
	}
}

class DestinyCards extends Component {
	render() {
		return(
			<div className="row condrow justify-content-center">
			
				<div className="col-*-5 text-center condHeaderCell" style={{marginTop: 'auto', marginBottom: 'auto'}}>
					<label htmlFor="destinyBoost">
						{/*<button className="btn destinyButton"
							onClick={
								(e) => {
									this.props.onDestinyBoostsChange(Math.max(this.props.destinyBoosts-3,0))
								}
							}>
							<svg className="bi bi-dash-circle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							  <path fill-rule="evenodd" d="M16 8A8 8 0 110 8a8 8 0 0116 0zM4 7.5a.5.5 0 000 1h8a.5.5 0 000-1H4z" clip-rule="evenodd"/>
							</svg>
						</button>*/}
						<span>Destiny Card Boosts</span>
						{/*<button className="btn destinyButton"
							onClick={
								(e) => {
									this.props.onDestinyBoostsChange(this.props.destinyBoosts+3)
								}
							}>
							<svg className="bi bi-plus-circle-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							  <path fill-rule="evenodd" d="M16 8A8 8 0 110 8a8 8 0 0116 0zM8.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clip-rule="evenodd"/>
							</svg>
						</button>*/}
					</label>
				</div>

				<div className="col-*-auto offset-2 text-left condCell" style={{marginTop: 'auto', marginBottom: 'auto'}}>
					<select id="destinyBoost" className="custom-select" style={{textAlignLast: 'center'}}
						value={this.props.destinyBoosts}
						onChange={
							(e) => {
								this.props.onDestinyBoostsChange(e.target.value);
							}
						}
					>
							<option value='0'>None</option>
							{ [...Array(10).keys()].map( ( nbr ) => (
								<option value={(nbr + 1)*3} key={nbr}>+ {(nbr + 1)*3}</option>
							) ) }
					</select>
				</div>
			</div>
		)
	}
}

export default RoorConditions;
